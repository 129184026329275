import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import vueEsign from "vue-esign"; //电子签名
import moment from "moment";
import store from "./vuex/store"; //1的新建文件

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "zh", // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    zh: require("./i18n/language_zh"),
    en: require("./i18n/language_en"),
  },
});
Vue.use(ElementUI);
Vue.use(vueEsign);
Vue.prototype.$moment = moment;
moment.locale("zh-cn");

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
