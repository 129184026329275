<template>
    <div>
        <el-card class="box-card" v-for="(item, index) in songs_finals" :key="index">
        <div slot="header" class="clearfix">
          <span>曲目</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="delSongs(index)"
            >删除</el-button
          >
        </div>
        <div>
          <el-input v-model="item.name" placeholder="曲名"></el-input>
          <el-input v-model="item.authur" placeholder="作者"></el-input>
          <el-input v-model="item.time" placeholder="时长"></el-input>
        </div>
      </el-card>
      <el-button style="margin-top: 12px" @click="addSongs">添加曲目</el-button>
      <div class="videoNote">
        视频要求
        <br />
        a、演奏者在光线明亮处，正面向摄像机镜头，设定合适的距离以确保演奏清晰可见。
        <br />
        b、比赛视频文件名以选手的姓名组别命名。
        <br />
        c、比赛视频不得进行艺术美化性质的编辑，包括图像和音频的剪辑拼接，且必须为同期录音，严格要求声像同步。
        <br />
        d、比赛视频文件格式为MP4 ,清晰度为1280*720p或720*576p。
        <br />
        e、比赛视频需上传至网盘，并在下方填写网盘地址及提取码。
        <br />
        f、报名录像资料不予退还。
        <div>
          <el-input v-model="videoLink_finals" placeholder="视频链接"></el-input>
          <el-input v-model="getCode_finals" placeholder="提取码"></el-input>
        </div>
      </div>
      支付
      <div>
        <p>Paypal账号：canadaecc.office@gmail.com</p>
        <p>银行卡号：5813390002614312</p>
        <el-upload
          ref="upload_payment_finals"
          :auto-upload="false"
          class="upload-demo"
          action="https://www.zalaki.top/api/IO/upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList_payment_finals"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <el-button
        style="margin-top: 12px"
        @click="commitFinalsInfo"
        >提交</el-button
      >
    </div>
</template>
<script>
import axios from "axios";
export default {
    name:'Finals',
    data(){
        return{
            songs_finals: [
                {
                    name: "",
                    authur: "",
                    time: "",
                },
            ],
            videoLink_finals: "",
            getCode_finals: "",
            fileList_payment_finals: [],
            formData: {},
        }
    },
    created(){
      this.initRegisterationInfo()
    },
    methods:{
        addSongs(){
            this.songs_finals.push({
                name: "",
                authur: "",
                time: "",
            })
        },
        delSongs(index){
            this.songs_finals.splice(index,1);
        },
        initRegisterationInfo() {
          var that = this;
          axios
            .get(process.env.VUE_APP_SERVER_URL + "/SUM/registrationInfo", {
              auth: {
                username: localStorage.getItem("token"),
                password: "",
              },
            })
            .then(function (res) {
              if (res.data.success&&res.data.data.is_finals_registed==='true') {
                var showData = res.data.data;
                //歌曲数据
                that.songs_finals = JSON.parse(showData.songs_finals);
                //网盘数据
                that.videoLink_finals = showData.video_link_finals;
                that.getCode_finals = showData.get_code_finals;
                //支付图片数据
                if (showData.Payment_finals_Img_url.length > 0) {
                  that.fileList_payment_finals.push({
                    name: showData.Payment_finals_Img_url.split("/")[
                      showData.Payment_finals_Img_url.split("/").length - 1
                    ],
                    url: process.env.VUE_APP_IMG_URL + showData.Payment_finals_Img_url,
                  });
                }
              } else {
                that.$message(res.data.message);
              }
              console.log(res);
            })
            .catch(function (err) {
              that.$message(err);
              console.log(err);
            });
        },
        commitFinalsInfo(){
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          var that = this;
          var sendData = {
            songs_finals: JSON.stringify(this.songs_finals),
            video_link_finals: this.videoLink_finals,
            get_code_finals: this.getCode_finals,
          };
          this.formData = new FormData();
          axios
          .put(
            process.env.VUE_APP_SERVER_URL + "/SUM/registrationInfo",
            sendData,
            {
              auth: {
                username: localStorage.getItem("token"),
                password: "",
              },
            }
          )
          .then(function (res) {
            if (res.data.success) {
              // 支付凭证照片
              if (that.$refs.upload_payment_finals.uploadFiles.length > 0) {
                that.$refs.upload_payment_finals.uploadFiles.forEach((file) => {
                  if (file.raw) {
                    that.formData.append("file", file.raw);
                    that.formData.append("Payment_finals_img", true);
                  } else {
                    that.formData.append("Payment_finals_img", false);
                  }
                });
              } else {
                that.formData.append("Payment_finals_img", "clean");
              }
              axios
                .post(
                  process.env.VUE_APP_SERVER_URL + "/IO/upload_finals",
                  that.formData,
                  {
                    auth: {
                      username: localStorage.getItem("token"),
                      password: "",
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success) {
                    loading.close();
                    that.$message("提交成功");
                    //跳转至报名信息页面
                    that.$router.push({ path: "/sign_up/show" });
                  } else {
                    this.$message.error("文件上传失败");
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.$message.error("文件上传失败");
                });
            } else {
              that.$message(res.data.message);
            }
            console.log(res);
          })
          .catch(function (err) {
            that.$message(err);
            console.log(err);
          });
        },
        handleRemove(file, fileList) {
          console.log(file, fileList);
        },
        handlePreview(file) {
          console.log(file);
        },
        handleExceed(files, fileList) {
          this.$message.warning(
            `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
              files.length + fileList.length
            } 个文件`
          );
        },
        beforeRemove(file, fileList) {
          console.log(fileList);
          return this.$confirm(`确定移除 ${file.name}？`);
        },
    }
}
</script>