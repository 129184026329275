<template>
  <div>
    <!-- <img
      src="../../assets/banner.jpg"
      alt=""
      style="width: 100%; margin-bottom: 50px"
    /> -->
    <h1 class="title_second">加拿大国际多元文化艺术节</h1>
    <h1 class="title_second">2023年第五届英才杯</h1>
    <h1 class="title_first">大赛章程</h1>
    <div class="constitution">
      <div class="key_point">
        <span class="bc_red">活动宗旨</span>
        <br />
        <p class="content">
          大赛秉承传承、发展、交流、融合的宗旨，为世界各国各民族音乐爱好者提供展示自己才华的机会，并从中发现人才，培养人才，实现对国际多元文化下音乐作品的学习和交流，促进国际多元文化在加拿大的发展。
        </p>
      </div>
      <div class="key_point">
        <span class="bc_red">活动行程</span>
        <br />
        <span class="bold">5月10日———7月25日：</span>网络线上报名，提交初赛视频
        <br />
        <span class="bold">7月01日：</span>第五届英才杯大赛新闻发布会
        <br />
        <span class="bold">8月06日——8月20日：</span>
        <br />
        1. 线上参赛选手：网络线上报名，提交决赛视频。
        <br />
        2. 线下参赛选手：网络线上报名，线下开始报到。
        <br />
        <span class="bold">8月29日：</span>开幕式欢迎会
        <br />
        <span class="bold">8月30日：</span>决赛
        <br />
        <span class="bold">8月31日：</span>与专家交流
        <br />
        <span class="bold">9月01日：</span>下午排练
        <br />
        <span class="bold">9月02日：</span>《今夜新星灿烂》音乐会 &lt;night of raising star&gt;
        <br />
        <span class="bc_red">比赛项目</span>
        <br />
        胡琴、古筝、琵琶、笛子、扬琴、中阮、柳琴、唢呐、笙、民族打击乐、大提琴、钢琴。
        <br />
        <span class="bc_red center" style="margin-top: 30px">比赛分组</span>
        <br />
        <span class="center">（参赛者以2023.7.1的年龄界定组别）</span>
        <br />
        <span class="bc_red">1、非职业组</span>
        <br />
        <span class="bold">一、少儿组：</span>
        <br />
        A组：4-6岁
        <br />
        B组：7-9岁
        <br />
        C组 ：10-13岁
        <br />
        <span class="bold">二、青少儿组：</span>
        <br />
        A组：14-17岁
        <br />
        B组：18-24岁
        <br />
        <span class="bold">三、成人组：</span>
        <br />
        A组：25-30岁
        <br />
        B组：31-45岁
        <br />
        C组：46-64岁
        <br />
        D组：64岁以上
        <br />
        <span class="bc_red">2、职业组</span>
        <br />
        <span class="bold">职业A组：</span>18岁以下
        <br />
        <span class="bold">职业B组：</span>19-30岁
        <br />
        <span class="bc_red">重奏组</span>
        <br />
        <span class="bold">重奏A组：</span>团员年龄为15岁以下
        <br />
        <span class="bold">重奏B组：</span>团员年龄为15岁或以上
        <br />
        <span class="bc_red">曲目要求</span>
        <br />
        按全曲准备，背谱演奏。所有演奏均要求无伴奏。
        <br />
        <span class="bold">独奏：</span>
        <br />
        1. 非职业组比赛全程选择自选曲目一首。
        <br />
        2. 职业组选择两首不同音乐时期的作品，其中一首必须是传统乐曲。初赛、决赛分别选择其中一首，顺序不限，不得重复。
        <br />
        <span class="bold">重奏：</span>
        <br />
        每组最多七人，乐器组合不拘，仅一首自选曲目，时长不超过8分钟。
        <br />
      </div>
      <div class="key_point">
        <span class="bc_red">比赛方式</span>
        <br />
        <span class="bold">（一）报名及初赛</span>
        <br />
        1、网络在线报名，请关注微信公众号：加拿大英才音乐交流获取比赛信息。5月10日-7月25日请登录比赛官方网站http://wwwcanadaecc.cn，连同初赛评审费一起交纳办理。上传初赛视频：（最晚7月25日提交初赛视频）视频文件以选手姓名+比赛项目+组别。视频上传到网络或常用社交平台（youtube，tiktok，instagram，bilibili，小红书，抖音，快手）然后复制链接到报名表。填写在线报名表时，请务必认真检查，姓名应与身份证明一致，一个联络手机号码电话只报名一位选手。
        <router-link to="login"
          ><img
            class="register_entry"
            src="../../assets/register_entry.jpg"
            alt="" /></router-link
        >
        <br />
        报名咨询电话：
        <br />
        +1（604）278-7730
        <br />
        +1（236）866-9895，
        <br />
        +1（236）9901193
        <br />
        请扫描下方二维码关注组委会微信公众号，以方便及时了解比赛最新通知信息。
        <br />
        <img src="../../assets/code.png" alt="" />
        <br />
        2、比赛一律采用评委审看选手录像视频的方式进行。
        <br />
        <span class="bold">视频要求</span>
        <div style="margin-left: 2em">
          a、演奏者在光线明亮处，正面向摄像机镜头，设定合适的距离以确保演奏清晰可见。
          <br />
          b、比赛视频文件名以选手的姓名组别命名。
          <br />
          c、比赛视频不得进行艺术美化性质的编辑，包括图像和音频的剪辑拼接，且必须为同期录音，严格要求声像同步。
          <br />
          d、比赛视频文件格式为MP4 ,清晰度为1280*720p或720*576p。
          <br />
          e、报名录像资料不予退还。
          <br />
        </div>
        3、初赛评审费：独奏类 $80加元/人 ，重奏组 $25加元/人。
        <br />
        4、选手资料和报名费全部收到后组委会将以电子邮件的形式告知选手确认报名成功。
        <br />
        5、入选决赛的名单会在微信公众号：加拿大英才音乐交流与活动官方网站www.canadaecc.cn发布。
        <br />
        <span class="bold">（二）决赛 </span>
        <br />
        入围参赛选手须于8月6日——8月20日按照初赛报名流程，在线办理下阶段比赛手续，未入围下阶段比赛的选手，组委会不再通知，所交资料和评审费不予退还。温哥华赛区入围选手必须参加线下评审。其他赛区入围选手可选择线上或线下评审，交通食宿一律自理。
        <br />
        <span class="bold">决赛时间： </span>2023年8月30日—8月31日
        <br />
        <span class="bold">决赛评审费： </span>$120加元/人
      </div>
      <div class="key_point">
        <span class="bc_red">奖励办法</span>
        <br />
        1、本次大赛各组别均设一等奖、二等奖、三等奖与优秀奖若干名。
        <br />
        2、获奖选手于2023年9月2日在温哥华参加“今夜新星灿烂”音乐会并颁发获奖证书。重奏组颁发集体奖证书和个人证书。
        <br />
        3、决赛总分第一名获得奖金300加元，第二名获得奖金200加元，第三名获得奖金100加元。
        <br />
        4、组委会将对荣获一等奖选手的指导教师以及有多名学生进入决赛的指导教师颁发"优秀指导教师奖"、"优秀组织奖"。
        <br />
        5、由加拿大英才音乐学院艺术委员会一致认可的优秀演奏者可获全额奖学金进入学院学习。
      </div>
      <div class="key_point">
        <span class="bc_red">比赛总规则</span>
        <br />
        1、比赛筹委会有权取消或是合并任何一组报名人数低于三人的项目。
        <br />
        2、历届获得大赛第一名者，不可参加相同乐器同年龄组赛事，可以晋级报名。
        <br />
        3、所有上传到各大平台的初赛影像，独奏乐器均不可含伴奏，违规者取消比赛资格。
        <br />
        4、所有乐曲必须是为该特定乐器所创作之原作。
        <br />
        5、参赛者可以注册报名多种器乐项目。
        <br />
        6、报名曲目在截止日后，一概不得更改。
        <br />
        7、职业组决赛者所有曲目必须背谱演奏完整乐曲，不得重复初赛曲目。
        <br />
        8、决赛时，由于时间限制评审可中断参赛者的演奏，但是不会影响评审的结果。
        <br />
        9、派送指导学生参赛之老师不可为自己的参赛学生伴奏。
        <br />
        10、若无符合入围标准的参赛者，评审有权决定该奖项空缺。
        <br />
        11、所有评审的决定不可更改。参赛者以及家长或监护人对评审的最终决定不得有异议;若被发现与评审有任何的讨论，参赛者或获奖者将被取消赛事资格。
        <br />
        12、评审不提供评语。
        <br />
        13、出现并列名次的情况时，并列获奖者将各获得一个奖杯及平均分享该项奖金。
        <br />
        14、第一名得主在优胜者音乐会的演奏曲目，将由评审指定。
        <br />
        15、得奖人将会于优胜者音乐会颁奖典礼领取奖杯、证书。如不能出席，可要求邮寄，主办单位酌手续及邮费-加拿大境内每奖项加币$30，加拿大境外每奖项加币$50。
        <br />
        16、主办单位加拿大英才音乐学院有权记录、使用、复制及公开在决赛及优胜者音乐会的影像资料。每位参赛者及家长同意自动放弃对于加拿大英才音乐学院及相关人员的权利及行动的诉讼。
      </div>
      <div class="key_point">
        <span class="bc_red">大赛评委</span>
        <br />
        大赛聘请海内外知名音乐家组成评委会。评委会对本次大赛的各轮次比赛有最终评定权。
      </div>
      <div class="key_point">
        <span class="bc_red">组织机构</span>
        <br />
        <span class="bold">支持单位：</span>加拿大BC省列治文政府
        <br />
        <span class="bold">主办单位：</span>加拿大英才中乐协会
        <br />
        <span class="bold">承办单位：</span>加拿大英才音乐学院
        <br />
        <span>详情请咨询加拿大英才音乐学院</span>
        <br />
        <img src="../../assets/contract.png" alt="">
        <br />
        联系电话
        <br />
        604-278-7730
        <br />
        联系地址
        <br />
        2360/2335-8888 Odlin Cr. Richmond, BC, Canada
        <br />
        联系邮箱
        <br />
        canadaecc.office@gmail.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}
p {
  text-indent: 2em;
  text-align: justify;
  margin: 4px 0;
  line-height: 30px;
}
pre {
  font-size: 16px;
}
.title_first {
  font-size: 28px;
  color: #c0504d;
  text-align: center;
}
.title_second {
  font-size: 22px;
  color: #c0504d;
  text-align: center;
}
.constitution {
  width: 95%;
  margin: 0 auto;
}
.key_point {
  margin: 30px 0;
  text-align: justify;
  line-height: 30px;
}
.key_point span {
  display: inline-block;
}
.key_point img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.key_point .bc_red {
  display: inline-block;
  font-size: 18px;
  background-color: #c0504d;
  color: white;
  margin-bottom: 3px;
  font-weight: bold;
}
.key_point .bold {
  font-weight: bold;
}
.key_point .small {
  font-size: 12px;
}
.key_point .ban {
  color: #ccc;
}
.key_point .red {
  color: #c0504d;
  font-weight: bold;
}
.key_point .center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.key_point .content {
  text-indent: 0;
  margin-left: 2em;
  margin-top: 0;
  text-indent: 2em;
}
.key_point .register_entry {
  width: 200px;
  position: fixed;
  bottom: 0;
  left: 100%;
  transform: translateX(-100%);
  z-index: 2;
}
.clickToGoIn {
  font-size: 26px;
  color: red;
  font-weight: bold;
}
</style>
