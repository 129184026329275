import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Login from "../views/login/Login.vue";
import SignUp from "../views/sign_up/index.vue";
import Show from "../views/sign_up/show.vue";
import Finals from "../views/sign_up/finals.vue";
import UserInfo from "../views/admin/index.vue";
import UserDetail from "../views/admin/show.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/sign_up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/sign_up/show",
    name: "Show",
    component: Show,
  },
  {
    path: "/sign_up/finals",
    name: "Finals",
    component: Finals,
  },
  {
    path: "/user",
    name: "UserInfo",
    component: UserInfo,
  },
  {
    path: "/user/:userId",
    name: "UserDetail",
    component: UserDetail,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
