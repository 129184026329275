<template>
  <div>
    <!-- <div v-for="(item,index) in showData" :key="index">
            {{index}} : {{item}}
        </div> -->
    <el-descriptions title="报名信息" :column='1'>
      <el-descriptions-item label="姓名"
        >{{ showData.first_name }}
        {{ showData.last_name }}</el-descriptions-item
      >
      <el-descriptions-item label="参赛组别">{{
        showData.value_CN
      }}</el-descriptions-item>
      <el-descriptions-item label="出生日期">{{
        showData.birthday
      }}</el-descriptions-item>
      <el-descriptions-item v-if="showData.sex == 'man'" label="性别"
        >男</el-descriptions-item
      >
      <el-descriptions-item v-else label="性别">女</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        showData.phone_number
      }}</el-descriptions-item>
      <el-descriptions-item label="邮箱">{{
        showData.email
      }}</el-descriptions-item>
      <el-descriptions-item label="老师姓名">{{
        showData.teacher_name
      }}</el-descriptions-item>
      <el-descriptions-item label="经验/简历">{{
        showData.resume
      }}</el-descriptions-item>
      <el-descriptions-item label="身份证/护照号码">{{
        showData.IDCard
      }}</el-descriptions-item>
      <el-descriptions-item label="身份证/护照号码发证国家">{{
        showData.country
      }}</el-descriptions-item>
      <el-descriptions-item label="经验/简历">{{
        showData.resume
      }}</el-descriptions-item>
      <el-descriptions-item label="地址">{{
        showData.address
      }}</el-descriptions-item>
      <el-descriptions-item label="护照照片">
        <img
          :src="img_http + showData.IDCard_Img_url"
          alt=""
          style="width: 200px"
        />
      </el-descriptions-item>
      <el-descriptions-item label="曲目清单">
        <ul>
          <li v-for="(item, index) in showData.songs" :key="index">
            曲名：{{ item.name }}
            <br />
            作者：{{ item.authur }}
            <br />
            时长：{{ item.time }}
          </li>
        </ul>
      </el-descriptions-item>
      <el-descriptions-item label="网盘连接">{{
        showData.video_link
      }}</el-descriptions-item>
      <el-descriptions-item label="提取码">{{
        showData.get_code
      }}</el-descriptions-item>
      <el-descriptions-item label="支付截图">
        <img
          :src="img_http + showData.Payment_Img_url"
          alt=""
          style="width: 200px"
        />
      </el-descriptions-item>
      <!-- <el-descriptions-item label="支付卡号">{{showData.card_number}}</el-descriptions-item>
            <el-descriptions-item label="支付卡有效期">{{showData.card_date}}</el-descriptions-item>
            <el-descriptions-item label="支付卡服务约束代码">{{showData.card_CVV}}</el-descriptions-item> -->
      <!-- <el-descriptions-item label="参赛者签名">
                <img :src="showData.sign_name_pic_base64" alt="" style="width:200px">
            </el-descriptions-item> -->
    </el-descriptions>
    <el-descriptions v-if="showData.is_finals_registed!==null" title="决赛信息">
      <el-descriptions-item label="曲目清单">
        <ul>
          <li v-for="(item, index) in showData.songs_finals" :key="index">
            曲名：{{ item.name }}
            <br />
            作者：{{ item.authur }}
            <br />
            时长：{{ item.time }}
          </li>
        </ul>
      </el-descriptions-item>
      <el-descriptions-item label="网盘连接">{{
        showData.video_link_finals
      }}</el-descriptions-item>
      <el-descriptions-item label="提取码">{{
        showData.get_code_finals
      }}</el-descriptions-item>
      <el-descriptions-item label="支付截图">
        <img
          :src="img_http + showData.Payment_finals_Img_url"
          alt=""
          style="width: 200px"
        />
      </el-descriptions-item>
    </el-descriptions>
    <el-dialog title="登记选手成绩" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="选手成绩" :label-width="formLabelWidth">
          <el-select v-model="form.promotion" placeholder="请选择选手成绩">
            <el-option v-for="(item,index) in promotion_list" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="registrationResultst"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="确认支付信息" :visible.sync="dialogPayInfo">
      <el-form>
        <el-form-item :label-width="formLabelWidth">
          <el-form-item label="备注信息" :label-width="formLabelWidth">
            <el-input v-model="payInfoForm.notes" autocomplete="off"></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPayInfo = false">取 消</el-button>
        <el-button type="primary" @click="confirmPayment"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-button style="margin-top: 12px" @click="goBack">返回</el-button>
    <el-button style="margin-top: 12px" @click="dialogPayInfo=true"
      >确认支付信息</el-button
    >
    <el-button style="margin-top: 12px" @click="dialogFormVisible = true"
      >登记成绩</el-button
    >
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "SignUp",
  data() {
    return {
      showData: {},
      dialogFormVisible: false,
      dialogPayInfo:false,
      promotion_list:[
          {label:'晋级决赛',value:0},
          {label:'金奖',value:1},
          {label:'银奖',value:2},
          {label:'铜奖',value:3},
          {label:'优秀演奏奖',value:4},
          {label:'优秀奖',value:5},
          {label:'未晋级',value:6},
          {label:'未获得奖项',value:7}
      ],
      form: {
        promotion: "",
      },
      payInfoForm:{
        notes:''
      },
      formLabelWidth: "120px",
      img_http:process.env.VUE_APP_IMG_URL
    };
  },
  created() {
    this.initRegisterationInfo();
  },
  mounted() {
    document.title = "信息填报";
  },
  methods: {
    initRegisterationInfo() {
      console.log(this.$route.params);
      var id = this.$route.params.userId;
      var that = this;
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/UM/userDetail/" + id, {
          auth: {
            username: localStorage.getItem("token"),
            password: "",
          },
        })
        .then(function (res) {
          if (res.data.success) {
            that.showData = res.data.data;
            that.showData.birthday = moment(that.showData.birthday).format(
              "yyyy-MM-DD"
            );
            that.showData.songs = JSON.parse(that.showData.songs);
            that.showData.songs_finals = JSON.parse(that.showData.songs_finals);
            console.log(res.data.data);
          } else {
            that.$message(res.data.message);
          }
          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
    goBack() {
      this.$router.push({ path: "/user" });
    },
    confirmPayment() {
      var that = this;
      axios
        .put(
          process.env.VUE_APP_SERVER_URL + "/UM/userConfirm",
          { id: this.$route.params.userId, status: "true",notes:this.payInfoForm.notes },
          {
            auth: {
              username: localStorage.getItem("token"),
              password: "",
            },
          }
        )
        .then(function (res) {
          if (res.data.success) {
            that.$message(res.data.msg);
          } else {
            that.$message(res.data.msg);
          }
          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
    registrationResultst() {
      var that = this;
      axios
        .put(
          process.env.VUE_APP_SERVER_URL + "/UM/userPromotion",
          { id: this.$route.params.userId, promotion: this.form.promotion },
          {
            auth: {
              username: localStorage.getItem("token"),
              password: "",
            },
          }
        )
        .then(function (res) {
          if (res.data.success) {
            that.$message(res.data.msg);
            that.dialogFormVisible=false;
          } else {
            that.$message(res.data.msg);
            that.dialogFormVisible=false;
          }
          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
