<template>
  <div class="login">
    <div id="login_box" v-if="ifLogin">
        <h1>Login</h1>
        <div class="form">
            <div class="item">
                <input type="text" v-model="username" placeholder="请输入用户名">
            </div>
            <div class="item">
                <input v-model="password" placeholder="请输入密码" type="password">
            </div>
            <el-button type="primary" @click="login">登录</el-button>
            <el-button type="primary" @click="showRegist">注册</el-button>
        </div>
    </div>
    <Regist v-else />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <span>{{promotionMsg}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="commitFinalMsg" type="primary" @click="completeFinalsMsg">完善信息</el-button>
        <el-button v-else type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Regist from "./Regist.vue";
export default {
  name: "Login",
  components: {
    Regist,
  },

  data() {
    return {
      username: "",
      password: "",
      dialogVisible: false,
      promotionMsg:'',
      commitFinalMsg:false
    };
  },
  computed: {
    ifLogin: function () {
      return this.$store.getters.getLoginState;
    },
  },
  created() {
    console.log(this.$store.getters.getNumber);
    this.$store.commit("addNumber");
    console.log(this.$store.getters.getNumber);
  },
  mounted() {
    document.title = "登录";
  },
  methods: {
    login() {
      var that = this;
      axios
        .post(process.env.VUE_APP_SERVER_URL + "/login", {
          username: this.username,
          password: this.password,
        })
        .then(function (res) {
          if (res.data.success) {
            that.$message("登录成功");
            //记录token
            localStorage.setItem("token", res.data.data.token);
            if (res.data.data.is_admin) {
              that.$router.push({ path: "/user" });
            } else if (res.data.data.is_registered) {
              // if(res.data.data.is_registered_finals){
              //   //跳转至报名信息展示页面
              //   that.$router.push({ path: "/sign_up/show" });
              // }else if(res.data.data.promotion){
              //   //弹窗给成绩
              //   that.dialogVisible=true;
              //   let promotionMsg='恭喜您：您获得的奖项是：';
              //   switch(res.data.data.promotion){
              //     case '0':
              //       promotionMsg='您已晋级决赛，请前往完善决赛信息。';
              //       that.commitFinalMsg=true;
              //       break;
              //     case '1':
              //       promotionMsg+='金奖！';
              //       that.commitFinalMsg=false;
              //       break;
              //     case '2':
              //       promotionMsg+='银奖！';
              //       that.commitFinalMsg=false;
              //       break;
              //     case '3':
              //       promotionMsg+='铜奖！';
              //       that.commitFinalMsg=false;
              //       break;
              //     case '4':
              //       promotionMsg+='优秀演奏奖！';
              //       that.commitFinalMsg=false;
              //       break;
              //     case '5':
              //       promotionMsg+='优秀奖！';
              //       that.commitFinalMsg=false;
              //       break;
              //     case '6':
              //       promotionMsg='您未晋级决赛，再接再厉！';
              //       that.commitFinalMsg=false;
              //       break;
              //     case '7':
              //       promotionMsg='您未获奖，再接再厉！';
              //       that.commitFinalMsg=false;
              //       break;
              //   }
              //   that.promotionMsg=promotionMsg;
              // }else{
              //   //弹窗提示成绩还没出来
              //   that.dialogVisible=true;
              //   that.promotionMsg='成绩暂未公布，请耐心等待！';
              // }
              // if(new Date('2022-06-21').getTime()-new Date().getTime()<0&&new Date('2022-07-6').getTime()-new Date().getTime()>0){
                
              //跳转至报名信息展示页面
              that.$router.push({ path: "/sign_up/show" });
            } else {
              //跳转至报名页面
              that.$router.push({ path: "/sign_up" });
            }
          } else {
            that.$message(res.data.message);
          }

          console.log(res);
        })
        .catch(function (err) {
          that.$message("登录失败");
          console.log(err);
        });
      console.log(111);
    },
    showRegist() {
      this.$store.commit("changeLogin", false);
    },
    completeFinalsMsg(){
      this.$router.push({ path: "/sign_up/finals" });
    }
  },
};
</script>

<style scoped>
.login{
  display: flex;
  justify-content: center;
  align-content: center;
}
#login_box{
    box-sizing: border-box;
    width: 350px;
    height: 250px;
    margin: 0 auto;
    margin-top: 15%;
    text-align: center;
    background-color: #00000070;/*可以在6位颜色后面直接加一个数字，表示透明度*/
    padding: 20px 50px;
}
#login_box h1{
    color: white;
}
#login_box .form .item{
    margin-top: 15px;
}
#login_box .form .item input{
    width: 180px;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid white;
    padding: 5px 10px;
    background-color: #ffffff00;
}
#login_box .form .item i{
color: white;
font-size: 14px;
}
#login_box button{
    width: 80px;
    height: 50px;
    margin-top: 15px;
    color: white;
    font-size: 20px;
    font-weight: 700;
    background-image: linear-gradient(to right,#bd3125 0%,#578bc3 100%);
    border: none;
    border-radius: 15px;
    margin-top: 20px;
    cursor: pointer;
}
</style>
