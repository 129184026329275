<template>
  <div>
    <!-- <div v-for="(item,index) in showData" :key="index">
            {{index}} : {{item}}
        </div> -->
    <el-descriptions title="初赛信息" :column='1'>
      <el-descriptions-item label="姓名"
        >{{ showData.first_name }}
        {{ showData.last_name }}</el-descriptions-item
      >
      <el-descriptions-item label="参赛组别">{{
        showData.value_CN
      }}</el-descriptions-item>
      <el-descriptions-item label="参赛乐器">{{
        showData.musicalInstrumentDescriptionCN
      }}</el-descriptions-item>
      <el-descriptions-item v-if="isPro" label="所在单位或院校">{{
        showData.unit
      }}</el-descriptions-item>
      <el-descriptions-item label="出生日期">{{
        showData.birthday
      }}</el-descriptions-item>
      <el-descriptions-item v-if="showData.sex == 'man'" label="性别"
        >男</el-descriptions-item
      >
      <el-descriptions-item v-else label="性别">女</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        showData.phone_number
      }}</el-descriptions-item>
      <el-descriptions-item label="邮箱">{{
        showData.email
      }}</el-descriptions-item>
      <el-descriptions-item label="老师姓名">{{
        showData.teacher_name
      }}</el-descriptions-item>
      <el-descriptions-item label="经验/简历">{{
        showData.resume
      }}</el-descriptions-item>
      <el-descriptions-item label="身份证/护照号码">{{
        showData.IDCard
      }}</el-descriptions-item>
      <el-descriptions-item label="身份证/护照号码发证国家">{{
        showData.country
      }}</el-descriptions-item>
      <el-descriptions-item label="地址">{{
        showData.address
      }}</el-descriptions-item>
      <el-descriptions-item label="护照照片">
        <img
          :src="img_http + showData.IDCard_Img_url"
          alt=""
          style="width: 200px"
        />
      </el-descriptions-item>
      <el-descriptions-item label="曲目清单">
        <ul>
          <li v-for="(item, index) in showData.songs" :key="index">
            曲名：{{ item.name }}
            <br />
            作者：{{ item.authur }}
            <br />
            时长：{{ item.time }}
          </li>
        </ul>
      </el-descriptions-item>
      <el-descriptions-item label="网盘连接">{{
        showData.video_link
      }}</el-descriptions-item>
      <el-descriptions-item label="提取码">{{
        showData.get_code
      }}</el-descriptions-item>
      <el-descriptions-item label="支付截图">
        <img
          :src="img_http + showData.Payment_Img_url"
          alt=""
          style="width: 200px"
        />
      </el-descriptions-item>
      <!-- <el-descriptions-item label="支付卡号">{{showData.card_number}}</el-descriptions-item>
            <el-descriptions-item label="支付卡有效期">{{showData.card_date}}</el-descriptions-item>
            <el-descriptions-item label="支付卡服务约束代码">{{showData.card_CVV}}</el-descriptions-item> -->
      <!-- <el-descriptions-item label="参赛者签名">
                <img :src="showData.sign_name_pic_base64" alt="" style="width:200px">
            </el-descriptions-item> -->
    </el-descriptions>
    <el-descriptions v-if="showData.is_finals_registed==='true'" title="决赛信息">
      <el-descriptions-item label="曲目清单">
        <ul>
          <li v-for="(item, index) in showData.songs_finals" :key="index">
            曲名：{{ item.name }}
            <br />
            作者：{{ item.authur }}
            <br />
            时长：{{ item.time }}
          </li>
        </ul>
      </el-descriptions-item>
      <el-descriptions-item label="网盘连接">{{
        showData.video_link_finals
      }}</el-descriptions-item>
      <el-descriptions-item label="提取码">{{
        showData.get_code_finals
      }}</el-descriptions-item>
      <el-descriptions-item label="支付截图">
        <img
          :src="img_http + showData.Payment_finals_Img_url"
          alt=""
          style="width: 200px"
        />
      </el-descriptions-item>
      <!-- <el-descriptions-item label="支付卡号">{{showData.card_number}}</el-descriptions-item>
            <el-descriptions-item label="支付卡有效期">{{showData.card_date}}</el-descriptions-item>
            <el-descriptions-item label="支付卡服务约束代码">{{showData.card_CVV}}</el-descriptions-item> -->
      <!-- <el-descriptions-item label="参赛者签名">
                <img :src="showData.sign_name_pic_base64" alt="" style="width:200px">
            </el-descriptions-item> -->
    </el-descriptions>
    <el-button style="margin-top: 12px" @click="completeInfo"
      >完善信息</el-button
    >
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Show",
  data() {
    return {
      showData: {},
      isPro: false,
      img_http:process.env.VUE_APP_IMG_URL
    };
  },
  created() {
    this.initRegisterationInfo();
  },
  mounted() {
    document.title = "信息填报";
  },
  methods: {
    initRegisterationInfo() {
      var that = this;
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/SUM/registrationInfo", {
          auth: {
            username: localStorage.getItem("token"),
            password: "",
          },
        })
        .then(function (res) {
          if (res.data.success) {
            that.showData = res.data.data;
            that.showData.birthday = moment(that.showData.birthday).format(
              "yyyy-MM-DD"
            );
            that.showData.songs = JSON.parse(that.showData.songs);
            that.showData.songs_finals = JSON.parse(that.showData.songs_finals);
            console.log(res.data.data);
            if (
              that.showData.group == "pro_a" ||
              that.showData.group == "pro_b"
            ) {
              that.isPro = true;
            }
          } else {
            that.$message(res.data.message);
          }
          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
    completeInfo() {
      if(this.showData.is_finals_registed==='true'){
        this.$router.push({ path: "/sign_up/finals" });
      }else{
        this.$router.push({ path: "/sign_up" });
      }
    },
  },
};
</script>

<style scoped></style>
