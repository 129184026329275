import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const state = {
  number: 0,
  ifLogin: true,
};
const mutations = {
  addNumber(state) {
    //aaNumber名称随意起
    state.number++;
  },
  changeLogin(state, loginState) {
    state.ifLogin = loginState;
  },
};
const getters = {
  getNumber() {
    //aaNumber名称随意起
    return state.number;
  },
  getLoginState() {
    return state.ifLogin;
  },
};
const store = new Vuex.Store({
  state,
  getters,
  mutations,
});
export default store;
