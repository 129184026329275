<template>
  <div>
    <!-- <img
      src="../../assets/banner.jpg"
      alt=""
      style="width: 100%; margin-bottom: 50px"
    /> -->
    <h1 class="title_second">Canadian Multicultural Art Festival</h1>
    <h1 class="title_second">Fifth Elite Cup</h1>
    <h1 class="title_first">Regulation</h1>
    <div class="constitution">
      <div class="key_point">
        <span class="bc_red">Purpose of the event</span>
        <br />
        <p class="content">
          The competition aims to provide an opportunity for music lovers from all over the world to showcase their talents, to discover and cultivate talents, to learn and exchange international multicultural music, and to promote the development of international multiculturalism in Canada.
        </p>
      </div>
      <div class="key_point">
        <span class="bc_red">Event Schedule</span>
        <br />
        <span class="bold">May 10 - July 25: </span>Online registration and submission of preliminary video
        <br />
        <span class="bold">July 01: </span>Press conference for the 5th Talent Cup Competition
        <br />
        <span class="bold">August 06 - August 20:</span>
        <br />
        1. Online contestants: online registration and submission of the final video.
        <br />
        2. Offline contestants: online registration, offline registration begins.
        <br />
        <span class="bold">August 29th:</span>Opening Welcome Party
        <br />
        <span class="bold">August 30th: </span>Final round
        <br />
        <span class="bold">August 31st:</span>Networking with experts
        <br />
        <span class="bold">September 1st:</span>Afternoon rehearsal
        <br />
        <span class="bold">September 2nd: </span>"Night of raising star" concert
        <br />
        <span class="bc_red">Competition Programs</span>
        <br />
        Huqin, Guzheng, Pipa, Flute, Yangqin, Zhongruan, Liuqin, Suona, Sheng, Folk Percussion, Cello, Piano.
        <br />
        <span class="bc_red center" style="margin-top: 30px">Categories</span>
        <br />
        <span class="center">(The date of 2023.7.1 will be used to define the categories)</span>
        <br />
        <span class="bc_red">1. Non-Professional Group</span>
        <br />
        <span class="bold">I. Mini & Junior:</span>
        <br />
        Group A: 4-6 years old
        <br />
        Group B: 7-9 years old
        <br />
        Group C: 10-13 years old
        <br />
        <span class="bold">II. Teens:</span>
        <br />
        Group A: 14-17 years old
        <br />
        Group B: 18-24 years old
        <br />
        <span class="bold">III. Adult:</span>
        <br />
        Group A: 25-30 years old
        <br />
        Group B: 31-45 years old
        <br />
        Group C: 46-64 years old
        <br />
        Group D: 64 years old and above
        <br />
        <span class="bc_red">2.Professional Group</span>
        <br />
        <span class="bold">Professional group A : </span>under 18 years old
        <br />
        <span class="bold">Professional group B : </span>19-30 years old
        <br />
        <span class="bc_red">Repertory</span>
        <br />
        <span class="bold">Repertory A: </span>Members under the age of 15
        <br />
        <span class="bold">Repertory B: </span>Members aged 15 years or above
        <br />
        <span class="bc_red">Requirements</span>
        <br />
        Prepare the piece in its entirety and play it from memory. All performances are required to be performed unaccompanied.
        <br />
        <span class="bold">Solo:</span>
        <br />
        1. One piece of your choice for the entire competition for the non-professional category.
        <br />
        2. In the professional category, two pieces from different musical periods will be chosen, one of which must be a traditional piece. In the preliminary and final rounds, one of the pieces must be chosen in any order and must not be repeated.
        <br />
        <span class="bold">Repertoire:</span>
        <br />
        The maximum number of players in each group is seven, with any combination of instruments, and only one piece of their choice, not exceeding 8 minutes in length.
        <br />
      </div>
      <div class="key_point">
        <span class="bc_red">Competition Method</span>
        <br />
        <span class="bold">I. Registration and Preliminary Round</span>
        <br />
        1.Online registration, pleasee follow the WeChat public number: Canada Talent Music Exchange for competition information. May 10 - July 25, please visit the official website of the competition at http://www.canadaecc.cn/ and pay for the application together with the preliminary round judging fee.
Upload preliminary video: (submit preliminary video no later than July 25)
The video file will be in the name of the contestant + competition item + group. 
Upload the video to the internet or to the usual social media platforms 
(youtube, tiktok, instagram, bilibili, Xiaohongshu, ShakeYin, Racer) 
Then copy the link to the registration form. 
When filling out the online registration form, please make sure to check carefully that your name is the same as your ID and that only one player is registered for one contact phone number.
<router-link to="login"
          ><img
            class="register_entry"
            src="../../assets/register_entry.jpg"
            alt="" /></router-link
        >
        <br />
        Enrollment consultation phone number:
        <br />
        +1（604）278-7730
        <br />
        +1（236）866-9895，
        <br />
        +1（236）9901193
        <br />
        Please scan the QR code to follow the organizing committee's WeChat public number to keep up with the latest information of the competition.
        <br />
        <img src="../../assets/code.png" alt="" />
        <br />
        2. All competitions will be held in the form of video recordings by the judges.
        <br />
        <span class="bold">Video requirements</span>
        <div style="margin-left: 2em">
          a. The performer should be in a brightly lit place, facing the camera lens and set at a suitable distance to ensure the performance is clearly visible.
          <br />
          b. The video file name should be named after the player's name and group.
          <br />
          c. The video of the competition shall not be edited for artistic beautification, including the editing and splicing of images and audio, and must be recorded at the same time, with strict requirements for synchronization of sound and image.
          <br />
          d. The format of the video file is MP4, with a clarity of 1280*720p or 720*576p.
          <br />
          e. Registration video materials are not refundable.
          <br />
        </div>
        3. Preliminary judging fee: $80 CAD/person for Solo category, $25 CAD/person for Repertoire category.
          <br />
        4. The organizing committee will send an email to the contestants after all the information and registration fees are received to confirm the success of their registration.
          <br />
        5. The list of finalists will be posted on 
        WeChat: Canada Excellence Music Exchange 
        and the official website: http://www.canadaecc.cn/ .
          <br />
        <span class="bold">II. Final Competition </span>
        <br />
        Finalists must apply for the next stage of the competition online from August 6 to August 20 in accordance with the application process of the preliminary round.
Finalists from Vancouver must attend the offline judging. Finalists from other regions can choose to judge online or offline, and will be responsible for their own transportation and accommodation.
        <br />
        <span class="bold">Finalist period:  </span>August 30 - August 31, 2023
        <br />
        <span class="bold">Judging Fee:  </span>$120 CAD per person
      </div>
      <div class="key_point">
        <span class="bc_red">Award Method</span>
        <br />
        1. There will be first, second, third and merit prizes in each category.
        <br />
        2. The winners will be presented with a certificate for their participation in the "Stars Tonight" concert in Vancouver on September 2, 2023. The repertoire group will receive a group award certificate and an individual certificate.
        <br />
        3. $300 for the first place, $200 for the second place and $100 for the third place in the final.
        <br />
        4. The organizing committee will award "Outstanding Instructor Award" and "Outstanding Organization Award" to the instructor of the first prize winner and to the instructor who has more than one student in the final.
        <br />
        5. The best performers unanimously approved by the Artistic Committee of the Canadian Y.C. Music Academy will be awarded full scholarships to study at the Academy.
      </div>
      <div class="key_point">
        <span class="bc_red">General rules of the competition</span>
        <br />
        1. The Competition Organizing Committee reserves the right to cancel or combine any group with less than three entries.
        <br />
        2. Previous winners of the first place in the competition may not participate in the same age group of the same instrument, but may advance to the next level of registration.
        <br />
        3. All preliminary images uploaded to all major platforms must not contain accompaniment for solo instruments, and violators will be disqualified.
        <br />
        4. All music must be original compositions written for the specific instrument.
        <br />
        5. Participants may register for multiple instrumental events.
        <br />
        6. No changes to the entries will be allowed after the closing date.
        <br />
        7. All finalists in the professional category must play the entire piece by heart and must not repeat the pieces from the preliminary round.
        <br />
        8. During the final round, the judge may interrupt the performance of the participant due to time constraints, but this will not affect the result of the judging.
        <br />
        9. Teachers who send their students to the competition may not accompany their own students. 
        <br />
        10. If there are no finalists who meet the criteria, the judges have the right to decide that the award is vacant.
        <br />
        11. All decisions of the judges are irrevocable. Participants and parents or guardians may not disagree with the final decision of the judges; if any discussion with the judges is found, the participant or winner will be disqualified from the event.
        <br />
        12. Judges will not provide comments.
        <br />
        13. In the event of a tie, the tied winners will each receive a trophy and share equally in the prize money.
        <br />
        14. The first place winner's repertoire for the winner's concert will be assigned by the judges. 
        <br />
        15. The winner will receive the trophy and certificate at the Winner's Concert. If you are unable to attend the concert, you may request to have your trophy and certificate mailed to you at the organizer's discretion.
        <br />
        16. The organizer, Canadian Y.C. Music Academy, reserves the right to record, use, reproduce and disclose video footage of the finalist and winner's concert. Each participant and parent agrees to automatically waive any and all rights and actions against the Academy of Music Excellence Canada and its affiliates.
      </div>
      <div class="key_point">
        <span class="bc_red">Jury of the Competition</span>
        <br />
        A jury of renowned musicians from home and abroad has been appointed for the competition. The jury will have the final say on all rounds of the competition.
      </div>
      <div class="key_point">
        <span class="bc_red">Organization</span>
        <br />
        <span class="bold">Government Support: </span>Government of Richmond, BC, Canada
        <br />
        <span class="bold">Organizer: </span>Chinese Music Association of Canada
        <br />
        <span class="bold">Undertaker: </span>Canadian Y.C. Music Academy
        <br />
        <span>For more information, please contact the Canadian Y.C. Music Academy</span>
        <br />
        <img src="../../assets/contract.png" alt="">
        <br />
        Contact Number
        <br />
        604-780-6693/604-278-7730
        <br />
        Contact Address
        <br />
        2360/2335-8888 Odlin Cr. Richmond, BC, Canada
        <br />
        Contact Email
        <br />
        canadaecc.office@gmail.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}
p {
  text-indent: 2em;
  text-align: justify;
  margin: 4px 0;
  line-height: 30px;
}
pre {
  font-size: 16px;
}
.title_first {
  font-size: 28px;
  color: #c0504d;
  text-align: center;
}
.title_second {
  font-size: 22px;
  color: #c0504d;
  text-align: center;
}
.constitution {
  width: 95%;
  margin: 0 auto;
}
.key_point {
  margin: 30px 0;
  text-align: justify;
  line-height: 30px;
}
.key_point span {
  display: inline-block;
}
.key_point img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.key_point .bc_red {
  display: inline-block;
  font-size: 18px;
  background-color: #c0504d;
  color: white;
  margin-bottom: 3px;
  font-weight: bold;
}
.key_point .bold {
  font-weight: bold;
}
.key_point .small {
  font-size: 12px;
}
.key_point .ban {
  color: #ccc;
}
.key_point .red {
  color: #c0504d;
  font-weight: bold;
}
.key_point .center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.key_point .content {
  text-indent: 0;
  margin-left: 2em;
  margin-top: 0;
  text-indent: 2em;
}
.key_point .register_entry {
  width: 200px;
  position: fixed;
  bottom: 0;
  left: 100%;
  transform: translateX(-100%);
  z-index: 2;
}
.clickToGoIn {
  font-size: 26px;
  color: red;
  font-weight: bold;
}
</style>
