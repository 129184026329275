<template>
  <div id="app">
    <div class="school_ad">
      <!-- <p class="remarks">点击查看学校官网</p> -->
      <a href="http://canadaycmusicacademy.com/" target="_blank"
        ><img src="../../assets/title_pic.jpg" alt="" style="width: 100%"
      /></a>
      <a
        href="http://canadaycmusicacademy.com/"
        target="_blank"
        style="text-decoration: none"
        ><p class="remarks">请点击以上图标查看学校官网</p></a
      >
    </div>
    <el-switch
      style="display: block"
      v-model="lang"
      active-color="#409EFF"
      inactive-color="#13ce66"
      active-text="English"
      inactive-text="中文"
    >
    </el-switch>
    <GeneralRules v-if="!lang" />
    <GeneralRules_EN v-else />
  </div>
</template>

<script>
import GeneralRules from "./GeneralRules.vue";
import GeneralRules_EN from "./GeneralRules_EN.vue";
export default {
  name: "App",
  components: {
    GeneralRules,
    GeneralRules_EN,
  },
  data() {
    return {
      lang: false,
    };
  },
  mounted() {
    document.title = "2023年第五届英才杯大赛报名入口";
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.school_ad {
  position: relative;
}
.remarks {
  text-align: left;
  font-size: 14px;
  margin: 0;
  margin-bottom: 50px;
  color: #409eff;
}
</style>
