<template>
  <div>
    <el-input v-model="username" placeholder="请输入用户名"></el-input>
    <el-input
      v-model="password"
      placeholder="请输入密码"
      show-password
    ></el-input>
    <el-input
      v-model="password_c"
      placeholder="请再次输入密码"
      show-password
    ></el-input>
    <el-button type="primary" @click="regist">注册</el-button>
            <el-button type="primary" @click="backToLogin">返回登录</el-button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Regist",

  data() {
    return {
      username: "",
      password: "",
      password_c: "",
    };
  },

  mounted() {},

  methods: {
    regist() {
      if (this.password != this.password_c) {
        this.$message("两次输入的密码不一致");
        return;
      }
      var that = this;
      axios
        .post(process.env.VUE_APP_SERVER_URL + "/login/register", {
          userName: this.username,
          password: this.password,
        })
        .then(function (res) {
          debugger;
          if (res.data.success) {
            that.$message("注册成功");
            //跳转至登录页面(利用vuex切换组件)
            that.$store.commit("changeLogin", true);
          } else {
            that.$message(res.data.msg);
          }
        })
        .catch(function (err) {
          that.$message("注册失败");
          console.log(err);
        });
    },
    backToLogin() {
      this.$store.commit("changeLogin", true);
    },
  },
};
</script>

<style scoped></style>
