<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="username" label="用户名" width="180">
      </el-table-column>
      <el-table-column prop="status" label="缴费状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status" style="color: green">已缴费</span>
          <span v-else style="color: red">未缴费</span>
        </template>
      </el-table-column>
      <el-table-column prop="promotion" label="晋级状态">
        <template slot-scope="scope">
          <span v-if="scope.row.promotion == null">——</span>
          <span v-if="scope.row.promotion==0">晋级决赛</span>
          <span v-if="scope.row.promotion==1">金奖</span>
          <span v-if="scope.row.promotion==2">银奖</span>
          <span v-if="scope.row.promotion==3">铜奖</span>
          <span v-if="scope.row.promotion==4">优秀演奏奖</span>
          <span v-if="scope.row.promotion==5">优秀奖</span>
          <span v-if="scope.row.promotion==6">未晋级</span>
          <span v-if="scope.row.promotion==7">未获得奖项</span>
        </template>
      </el-table-column>
      <el-table-column prop="notes" label="备注" width="180">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
// import moment from "moment";
export default {
  name: "SignUp",
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.initUserInfoList();
  },
  mounted() {
    document.title = "用户列表";
  },
  methods: {
    initUserInfoList() {
      var that = this;
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/UM/userList", {
          auth: {
            username: localStorage.getItem("token"),
            password: "",
          },
        })
        .then(function (res) {
          that.tableData = res.data.data;
          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
    handleClick(row) {
      this.$router.push({ name: "UserDetail", params: { userId: row.id } });
      console.log(row);
    },
  },
};
</script>

<style scoped></style>
