<template>
  <div>
    <el-steps :active="active">
      <el-step title="参赛须知" icon="el-icon-edit"></el-step>
      <el-step title="参赛者资料" icon="el-icon-upload"></el-step>
      <el-step title="参赛曲目" icon="el-icon-picture"></el-step>
    </el-steps>
    <el-form ref="ruleForm" :model="form" :rules="rules">
      <div v-if="active == 1">
        <div class="constitution">
          <div class="key_point">
            <span class="bc_red">比赛方式</span>
            <br />
            报名起止时间：5月10日—7月25日
            <br />
            初赛时间：7月30日-8月6日
            <br />
            决赛时间：8月30日
            <br />
            请准确填写以下申请表格用以参加2023年第五届英才杯大赛。
            <br />
            如您需要任何帮助，请发电邮canadaycmusicacademy@gmail.com与我们联系。
            <br />
            ⚠️ 注意：如果发现信息不实或不正确，主办方保留取消参赛资格的权利。
          </div>
        </div>
      </div>
      <div v-if="active == 2">
        <el-form-item label="组别" prop="group">
          <el-select
            v-model="form.group"
            placeholder="请选择参赛组别"
            value-key="id"
            @visible-change="handleChangeFlag"
          >
            <el-option
              v-for="(item, index) in groupOption"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="乐器" prop="musicalInstrument">
          <el-select
            v-model="form.musicalInstrument"
            placeholder="请选择参赛乐器"
            value-key="id"
          >
            <el-option
              v-for="(item, index) in musicalInstrumentOption"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参赛者姓名">
          <el-input
            v-model="form.first_name"
            placeholder="first name"
          ></el-input>
          <el-input v-model="form.last_name" placeholder="last name"></el-input>
        </el-form-item>
        <el-form-item label="所在单位或院校" v-if="isPro" prop="unit">
          <el-input v-model="form.unit" placeholder="所在单位或院校"></el-input>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker
            v-model="form.birthday"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio label="man">男</el-radio>
            <el-radio label="woman">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联络号码" prop="phone_number" required>
          <el-input v-model="form.phone_number"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" required>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="老师姓名">
          <el-input v-model="form.teacher_name"></el-input>
        </el-form-item>
        <el-form-item label="经验/简历">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入经验/简历"
            v-model="form.resume"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="身份证/护照号码" prop="IDCard">
          <el-input v-model="form.IDCard" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="身份证/护照号码发证国家" prop="country">
          <el-input v-model="form.country" placeholder=""></el-input>
          <!-- <el-select filterable v-model="form.country" placeholder="" value-key="id" >
                        <el-option v-for="(item, index) in allCountry" :key="index" :label="item.name" :value="item"></el-option>
                    </el-select> -->
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="form.address" placeholder="Address"></el-input>
          <!-- <el-input v-model="form.country_address" placeholder="Country"></el-input>
                    <el-input v-model="form.province_address" placeholder="State/Province/Regio"></el-input>
                    <el-input v-model="form.postal" placeholder="Postal/Zip Code"></el-input> -->
        </el-form-item>
        <el-form-item label="上传护照首页照片" required>
          <el-upload
            ref="upload_passport"
            :auto-upload="false"
            class="upload-demo"
            action="https://www.zalaki.top/api/IO/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :http-request="httpRequest"
            :file-list="fileList_passport"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('ruleForm')">上一步</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >下一步</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div v-if="active == 3">
      <el-card class="box-card" v-for="(item, index) in songs" :key="index">
        <div slot="header" class="clearfix">
          <span>曲目</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="delSongs(index)"
            >删除</el-button
          >
        </div>
        <div>
          <el-input v-model="item.name" placeholder="曲名"></el-input>
          <el-input v-model="item.authur" placeholder="作者"></el-input>
          <el-input v-model="item.time" placeholder="时长"></el-input>
        </div>
      </el-card>
      <el-button style="margin-top: 12px" @click="addSongs">添加曲目</el-button>
      <div class="videoNote">
        视频要求
        <br />
        a、演奏者在光线明亮处，正面向摄像机镜头，设定合适的距离以确保演奏清晰可见。
        <br />
        b、比赛视频文件名以选手的姓名组别命名。
        <br />
        c、比赛视频不得进行艺术美化性质的编辑，包括图像和音频的剪辑拼接，且必须为同期录音，严格要求声像同步。
        <br />
        d、比赛视频文件格式为MP4 ,清晰度为1280*720p或720*576p。
        <br />
        e、比赛视频需上传至网盘，并在下方填写网盘地址及提取码。
        <br />
        f、报名录像资料不予退还。
        <div>
          <el-input v-model="videoLink" placeholder="视频链接"></el-input>
          <el-input v-model="getCode" placeholder="提取码"></el-input>
        </div>
      </div>
      支付
      <div>
        <p>Paypal账号：canadaecc.office@gmail.com</p>
        <p>银行卡号：5813390002614312</p>
        <el-button class="openDialogPayByWechat" @click="dialogPayByWechat=true">微信支付</el-button>
        <el-upload
          ref="upload_payment"
          :auto-upload="false"
          class="upload-demo"
          action="https://www.zalaki.top/api/IO/upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :http-request="httpRequest"
          :file-list="fileList_payment"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
        <el-dialog class="dialogPayByWechat" title="微信支付向导" :visible.sync="dialogPayByWechat">
          <p>
            1、长按下图识别二维码
            <img src="../../assets/img/pay/payCode.jpg" alt="">
          </p>
          <p>
            2、输入金额
            <img src="../../assets/img/pay/payGuideOne.png" alt="">
          </p>
          <p>
            3、截图保存并上传
            <img src="../../assets/img/pay/payGuideTwo.png" alt="">
          </p>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogPayByWechat = false">关 闭</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div v-if="active == 1 || active == 3">
      <el-button style="margin-top: 12px" @click="prev">上一步</el-button>
      <el-button style="margin-top: 12px" @click="next" v-if="active == 1"
        >下一步</el-button
      >
      <el-button
        style="margin-top: 12px"
        @click="commitRegisterationInfo"
        v-if="active == 3"
        >提交</el-button
      >
    </div>

    <!-- <span>{{ $t('lang.name')}}</span>
        <span @click="changeLaguages()">切换语言</span> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "SignUp",
  data() {
    return {
      active: 1,
      form: {
        first_name: "",
        last_name: "",
        birthday: "",
        sex: "",
        email: "",
        phone_number: "",
        teacher_name: "",
        resume: "",
        IDCard: "",
        country: "",
        group: "",
        address: "",
        unit: "",
        musicalInstrument:''
      },
      songs: [
        {
          name: "",
          authur: "",
          time: "",
        },
      ],
      rules: {
        unit: [
          { required: true, message: "请输入所在单位或院校", trigger: "blur" },
        ],
        group: [{ required: true, message: "请选择组别", trigger: "change" }],
        musicalInstrument: [{ required: true, message: "请选择乐器", trigger: "change" }],
        birthday: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        phone_number: [
          { required: true, trigger: "blur" }, //validator: validatePhone,
        ],
        email: [
          {
            required: true, //是否必填
            message: "请输入邮箱地址", //错误提示信息
            trigger: "blur", //检验方式（blur为鼠标点击其他地方，）
          },
          {
            type: "email", //要检验的类型（number，email，date等）
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        IDCard: [
          { required: true, message: "请输入身份证/护照号", trigger: "blur" },
        ],
        country: [
          {
            required: true,
            message: "请选择身份证/护照号发证国家",
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      fileList_passport: [],
      fileList_payment: [],
      changeFlag: false,
      groupOption: [
        { id: "pro_a", name: "职业A组（18以下）" },
        { id: "pro_b", name: "职业B组（19-30）" },
        { id: "unpro_child_a", name: "非职业-少儿A组（4-6）" },
        { id: "unpro_child_b", name: "非职业-少儿B组（7-9）" },
        { id: "unpro_child_c", name: "非职业-少儿C组（10-13）" },
        { id: "unpro_adolescent_a", name: "非职业-青少年A组（14-17）" },
        { id: "unpro_adolescent_b", name: "非职业-青少年B组（18-24）" },
        { id: "unpro_adult_a", name: "非职业-成人A组（25-30）" },
        { id: "unpro_adult_b", name: "非职业-成人B组（31-45）" },
        { id: "unpro_adult_c", name: "非职业-成人C组（46-64）" },
        { id: "unpro_adult_d", name: "非职业-成人D组（65+）" },
        { id: "quartet_a", name: "重奏A组（团员年龄为15岁以下）" },
        { id: "quartet_b", name: "重奏B组（团员年龄为15岁或以上）" },
      ],/*  */
      musicalInstrumentOption:[
        { id: "huqin", name: "胡琴" },
        { id: "guzheng", name: "古筝" },
        { id: "pipa", name: "琵琶" },
        { id: "flute", name: "笛子" },
        { id: "dulcimer", name: "扬琴" },
        { id: "zhongruan", name: "中阮" },
        { id: "liuqin", name: "柳琴" },
        { id: "suonaHorn", name: "唢呐" },
        { id: "sheng", name: "笙" },
        { id: "ethnicPercussion", name: "民族打击乐" },
        { id: "cello", name: "大提琴" },
        { id: "piano", name: "钢琴" },
        // { id: "soloOrEnsemble", name: "独奏或重奏" },
      ],
      // allCountry:[
      //     {id:'China',name:'中国'},
      //     {id:'Mongolia',name:'蒙古'},
      //     {id:'North Korea',name:'朝鲜'},
      //     {id:'South Korea',name:'韩国'},
      //     {id:'Japan',name:'日本'}
      // ],
      lineWidth: 6,
      lineColor: "black",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      videoLink: "",
      getCode: "",
      cardNumber: "",
      cardDate: "",
      cardCVV: "",
      formData: {},
      isPro: false,
      dialogPayByWechat:false
    };
  },
  created() {
    this.initRegisterationInfo();
  },
  mounted() {
    document.title = "信息填报";
  },
  watch: {
    "form.phone_number": function (curVal, oldVal) {
      console.log(oldVal);
      if (!curVal) {
        this.form.phone_number = "";
        return false;
      }
      // 实时把非数字的输入过滤掉
      this.form.phone_number = curVal.match(/\d/gi)
        ? curVal.match(/\d/gi).join("")
        : "";
    },
    // 'form.firstGroup'(e) {
    //     if( e && e.id ){
    //         this.form.secondGroup='';
    //         this.form.thirdGroup='';
    //         switch(e.id){
    //             case 'pro':
    //                 this.secondGroupOption=[{id:'A',name:'职业A组：16-20岁'},{id:'B',name:'职业B组：21-35岁'}];
    //                 break;
    //             case 'unpro':
    //                 this.secondGroupOption=[{id:'child',name:'少儿组'},{id:'adult',name:'成年组'}];
    //                 break;
    //             default:
    //                 this.secondGroupOption=[];
    //                 this.thirdGroupOption=[];
    //                 break;
    //         }
    //     }
    // },
    // 'form.secondGroup'(e) {
    //     if( this.changeFlag ){
    //         this.form.area = {}
    //     }
    //     if( e && e.id ){
    //         switch(e.id){
    //             case 'child':
    //                 this.thirdGroupOption=[{id:'A',name:'A组：6-9岁'},{id:'B',name:'B组：10-13岁'},{id:'C',name:'C组 ：14-17岁'}];
    //                 break;
    //             case 'adult':
    //                 this.thirdGroupOption=[{id:'A',name:'A组：18-35岁'},{id:'B',name:'B组：36-64岁'},{id:'C',name:'C组：65岁以上'}];
    //                 break;
    //             default:
    //                 this.thirdGroupOption=[];
    //                 break;
    //         }
    //     }
    // }
  },
  methods: {
    addSongs() {
      this.songs.push({ name: "", authur: "", time: "" });
    },
    delSongs(index) {
      if (this.songs.length == 1) {
        this.$message("至少需要一首曲目");
        return;
      } else {
        this.songs.splice(index, 1);
      }
    },
    next() {
      if (this.active == 3) {
        return;
      } else {
        this.active++;
      }
      window.scrollTo(0, 0);
    },
    prev() {
      if (this.active == 1) {
        return;
      } else {
        this.active--;
      }
      window.scrollTo(0, 0);
    },
    changeLaguages() {
      let lang = this.$i18n.locale === "zh" ? "en" : "zh";
      this.$i18n.locale = lang;
    },
    submitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.submitUpload_all();
          that.next();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log(formName);
      // this.$refs[formName].resetFields();
      this.prev();
    },
    initRegisterationInfo() {
      var that = this;
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/SUM/registrationInfo", {
          auth: {
            username: localStorage.getItem("token"),
            password: "",
          },
        })
        .then(function (res) {
          if (res.data.success) {
            var showData = res.data.data;
            //表单数据
            that.form.group = showData.group;
            if (that.form.group == "pro_a" || that.form.group == "pro_b") {
              that.isPro = true;
            }
            that.form.musicalInstrument = showData.musical_instrument;
            that.form.first_name = showData.first_name;
            that.form.last_name = showData.last_name;
            that.form.unit = showData.unit;
            that.form.sex = showData.sex;
            that.form.birthday = new Date(
              moment(showData.birthday).format("yyyy-MM-DD")
            );
            that.form.email = showData.email;
            that.form.phone_number = showData.phone_number;
            that.form.teacher_name = showData.teacher_name;
            that.form.resume = showData.resume;
            that.form.IDCard = showData.IDCard;
            that.form.country = showData.country;
            that.form.address = showData.address;
            if (showData.IDCard_Img_url.length > 0) {
              that.fileList_passport.push({
                name: showData.IDCard_Img_url.split("/")[
                  showData.IDCard_Img_url.split("/").length - 1
                ],
                url: process.env.VUE_APP_IMG_URL + showData.IDCard_Img_url,
              });
            }
            if (showData.Payment_Img_url.length > 0) {
              that.fileList_payment.push({
                name: showData.Payment_Img_url.split("/")[
                  showData.Payment_Img_url.split("/").length - 1
                ],
                url: process.env.VUE_APP_IMG_URL + showData.IDCard_Img_url,
              });
            }
            //歌曲数据
            that.songs = JSON.parse(showData.songs);
            //网盘数据
            that.videoLink = showData.video_link;
            that.getCode = showData.get_code;
            //签名数据
            that.resultImg = showData.sign_name_pic_base64;
            //支付信息
            that.cardNumber = showData.card_number;
            that.cardDate = showData.card_date;
            that.cardCVV = showData.card_CVV;
          } else {
            that.$message(res.data.message);
          }

          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
    commitRegisterationInfo() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var that = this;
      var sendData = {
        form_data: this.form,
        songs: JSON.stringify(this.songs),
        video_link: this.videoLink,
        get_code: this.getCode,
        sign_name_pic_base64: this.resultImg,
        payment_info: {
          card_number: this.cardNumber,
          card_date: this.cardDate,
          card_cvv: this.CVV,
        },
      };
      sendData.form_data.birthday = moment(sendData.form_data.birthday).format(
        "yyyy-MM-DD"
      );
      axios
        .post(
          process.env.VUE_APP_SERVER_URL + "/SUM/registrationInfo",
          sendData,
          {
            auth: {
              username: localStorage.getItem("token"),
              password: "",
            },
          }
        )
        .then(function (res) {
          if (res.data.success) {
            // 支付凭证照片
            if (that.$refs.upload_payment.uploadFiles.length > 0) {
              that.$refs.upload_payment.uploadFiles.forEach((file) => {
                if (file.raw) {
                  that.formData.append("file", file.raw);
                  that.formData.append("Payment_img", true);
                } else {
                  that.formData.append("Payment_img", false);
                }
              });
            } else {
              that.formData.append("Payment_img", "clean");
            }
            axios
              .post(
                process.env.VUE_APP_SERVER_URL + "/IO/upload",
                that.formData,
                {
                  auth: {
                    username: localStorage.getItem("token"),
                    password: "",
                  },
                }
              )
              .then((res) => {
                if (res.data.success) {
                  loading.close();
                  that.$message("提交成功");
                  //跳转至报名页面
                  that.$router.push({ path: "/sign_up/show" });
                  // this.get_data()
                  // this.resetForm('ruleForm')
                  // this.fileobj=[]
                } else {
                  this.$message.error("文件上传失败");
                }
              })
              .catch((err) => {
                console.log(err);
                this.$message.error("文件上传失败");
              });
          } else {
            that.$message(res.data.message);
          }

          console.log(res);
        })
        .catch(function (err) {
          that.$message(err);
          console.log(err);
        });
    },
    handleChangeFlag(type) {
      // select回调，判断当前下拉框是否展示
      console.log(type);
      if (this.form.group == "pro_a" || this.form.group == "pro_b") {
        this.isPro = true;
      } else {
        this.isPro = false;
      }
    },
    handleReset() {
      this.$refs.esign.reset();
    },
    // 生成base64格式
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res; //把base64赋给img
          console.log(this.resultImg);
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 手动上传（证件）
    submitUpload_passport() {
      console.log(this.$refs.upload_passport.uploadFiles); //拿到上传的文件(思路：把所有的图片文件都拿到，然后何在一起进行上传)
      this.formData = new FormData();
      var that = this;
      // 将上传的文件放进formData对象
      this.$refs.upload_passport.uploadFiles.forEach((file) => {
        debugger;
        that.formData.append("file", file.raw);
      });
      this.formData.append("asdf", "asdf");
      console.log(this.formData);

      // this.$refs.upload_passport.submit()
    },
    httpRequest(params) {
      var FileExt = params.file.name.split(".")[1];
      var file_type = ["jpg", "jpeg", "png"].indexOf(FileExt) === -1;
      //拿到上传的文件的格式，限制上传的文件格式
      if (file_type) {
        this.$message({
          type: "warning",
          message: "请上传后缀名为、jpg、jpeg、png.",
        });
        this.fileList = [];
        return;
      }
      const isLt2M = params.file.size / 1024 / 1024 < 100;
      //限制上传的文件大小
      if (!isLt2M) {
        this.$message.warning("上传的文件大小不能超过100MB");
        return;
      }
      console.log(params.file); //拿到上传的文件
      var formdata = new FormData();
      formdata.append("file", params.file);
      // formdata.append('name', this.ruleForm.filename)
      // formdata.append('desc', this.ruleForm.desc)
      axios
        .post(process.env.VUE_APP_SERVER_URL + "/IO/upload", formdata, {
          auth: {
            username: localStorage.getItem("token"),
            password: "",
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("文件上传成功");
            // this.get_data()
            // this.resetForm('ruleForm')
            // this.fileobj=[]
          } else {
            this.$message.error("文件上传失败");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("文件上传失败");
        });
    },
    submitUpload_all() {
      this.formData = null;
      this.formData = new FormData();
      var that = this;
      // 证件彩色照片
      if (this.$refs.upload_passport.uploadFiles.length > 0) {
        this.$refs.upload_passport.uploadFiles.forEach((file) => {
          if (file.raw) {
            that.formData.append("file", file.raw);
            that.formData.append("IDCard_img", true);
          } else {
            that.formData.append("IDCard_img", false);
          }
        });
      } else {
        this.formData.append("IDCard_img", "clean");
      }

      console.log(this.formData);
    },
  },
};
// 手机号验证
// const validatePhone = (rule, value, callback) => {
//   if (value === "") {
//     callback(new Error("请输入手机号"));
//   } else {
//     if (!/^1[3456789]\d{9}$/.test(value)) {
//       callback(new Error("请输入正确的手机号"));
//     } else {
//       callback();
//     }
//   }
// };
</script>

<style scoped>
p {
  text-indent: 2em;
  text-align: justify;
  margin: 4px 0;
  line-height: 30px;
}
pre {
  font-size: 16px;
}
.title_first {
  font-size: 28px;
  color: #c0504d;
  text-align: center;
}
.title_second {
  font-size: 22px;
  color: #c0504d;
  text-align: center;
}
.constitution {
  width: 95%;
  margin: 0 auto;
}
.key_point {
  margin: 30px 0;
  text-align: justify;
  line-height: 30px;
}
.key_point span {
  display: inline-block;
}
.key_point img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.key_point .bc_red {
  display: inline-block;
  font-size: 18px;
  background-color: #c0504d;
  color: white;
  margin-bottom: 3px;
  font-weight: bold;
}
.key_point .bold {
  font-weight: bold;
}
.key_point .small {
  font-size: 12px;
}
.key_point .ban {
  color: #ccc;
}
.key_point .red {
  color: #c0504d;
  font-weight: bold;
}
.key_point .center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.key_point .content {
  text-indent: 0;
  margin-left: 7em;
  margin-top: 0;
}
.key_point .register_entry {
  width: 200px;
  position: fixed;
  bottom: 0;
  left: 100%;
  transform: translateX(-100%);
  z-index: 2;
}
.openDialogPayByWechat{
  margin-left: 2em;
}
.dialogPayByWechat p{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}
.dialogPayByWechat img{
  width:100%
}
</style>
